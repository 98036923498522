import React from "react";

const NewsLetterIntegration = () => {
  return (
    <>
      <iframe
        width='540'
        height='660'
        src='https://82a1019e.sibforms.com/serve/MUIFAJtybcr5P7J--bbRyKE7DcEi_0HCTEqCGczxp_kVR-5MyxcP1bzQShLIK7DTiiSHbmAumiwsuQGSic0GoqzYJao332vJXMCDIzw-AT5aN5mRihrIFBh5C2GZNIgu8Au-v5AHXmpHpY9FEGhBIjpiN2lGOf_hG-Gfe5wqBoroG3Bh0a8jY8FgC0tHEyDhJEaQ9eclq1QEZ6s1'
        frameborder='0'
        scrolling='auto'
        allowfullscreen
        title='NewsLetter Integration'
        style={{
          display: "block",
          maxWidth: "100%",
          borderRadius: "10px",
          border: "2.5px solid green",
        }}
      ></iframe>
    </>
  );
};

export default NewsLetterIntegration;
