import React from "react";
// import NavbarFullscreen from "./components/navbar-components/NavbarFullscreen";
import HomePage from "./components/home-page/Homepage";

export default function Test() {
  return (
    <div>
      <HomePage />
    </div>
  );
}
